<template>
	<div class="baseBody">
		<ScaleBox>
			<div class="main-wraper relative">
        <div id="container" style="width: 100%;height: 100%;position: absolute;z-index: 100;left: 0px;top: 100px;">
        </div>
				<div class="dateWeek"><DynamicDate></DynamicDate></div>
				<div class="fullBox"><ScreenfullBox></ScreenfullBox></div>
				<div class="baseTitleContent">
					<div class="baseTitleMid">
						<div class="top">
							<div class="topLine">
								<div class="title text-center base-font-60 text-white text-bold">{{title?title:'智慧安保支队实时看板'}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex align-center justify-between">
					<div>
						<div class="baseTitleLeft"></div>
					</div>
					<div>
						<div class="baseTitleRight"></div>
					</div>
				</div>
        <div class="baseCallAlarm " @click="showBj">
            <div class="flex align-center">
              <div class="bjImg"><el-image :src="bjImg"></el-image></div>
              <div class="title">一键报警</div>
            </div>
        </div>
        
        <div class="basePoiBox">
          <div class="relative">
            <div class="poiIcon">
                      <img class="el-image" :src="mapIcon" alt="">
                  </div>
                  <div class="poi-content">
                    <div>
                      <input type="text" v-model="keyword" id="pickerInput" placeholder="输入搜索位置" maxlength="256" autocomplete="off" class="active" data-spm-anchor-id="">
                      <div id="searchResults" ></div>
                    </div>
                  </div>
            <i class="el-icon-search"></i>
          </div>
        </div>

        <el-dialog
          :visible.sync="dialogVisible"
          append-to-body
          center
          custom-class="dialogCustom"
          :show-close="false"
          width="30%"
          :before-close="handleClose">
         <div class="text-white" >
          <div class="flex aling-cneter justify-between">
            <div class="base-font-17">呼叫内容</div>
            <i class="el-icon-circle-close base-font-20" @click="dialogVisible = false"></i>
          </div>
          <div class="dialogBox">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="呼叫地址">
              <el-input type="textarea" v-model="form.address"></el-input>
            </el-form-item>
          <el-form-item label="呼叫范围">
            <el-select @change="handleSelectChange" v-model="form.distance" placeholder="请选择活动区域" style="width:100%;">
              <el-option :label="item.name" :value="item.value" v-for="(item,index) in rangeList" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="呼叫人员">
            <el-input type="textarea" disabled v-model="sendDeviceNum"></el-input>
          </el-form-item>
          <el-form-item label="呼叫内容">
            <el-input type="textarea" v-model="form.content" maxlength="200"></el-input>
          </el-form-item>
          </el-form>
        </div>
         </div>
          <span slot="footer" class="dialog-footer">
            <div class="flex align-center justify-center">
              <div class="dialogBtn qx" @click="dialogVisible = false">取消呼叫</div>
              <div class="dialogBtn qd" @click="sendSubmit">确定呼叫</div>
            </div>
            <!-- <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
          </span>
        </el-dialog>

			</div>
		</ScaleBox>
	</div>
</template>

<script>

  import AMapLoader from "@amap/amap-jsapi-loader";
  import DynamicDate from "@/components/dynamicDate/index.vue";
  import ScreenfullBox from "@/components/screenfull/index.vue";
  import ScaleBox from "@/components/sacleBox/index.vue"
  import bjImg from "@/assets/images/tel.png";
  import mapIcon from "@/assets/images/mapIcon.png";

  import {sendSms, pcsCenterData, pcsListOnlineDevice, pcsSelectNearbyDeviceList} from "@/api/api";
  export default {
    components: {
      ScaleBox,
      DynamicDate,
      ScreenfullBox
    },
		data() {
			return {
        map:'',
        id:'',
        title:'',
        timer:null,
        lineList:[],
        postionList:[],
        deviceMakerList:[],
        runMakerList:[],
        bjImg:bjImg,
        mapIcon:mapIcon,
        keyword:'',
        dialogVisible:false,
        form:{
          workerIds:'',
          address:'',
          longitude:'',
          latitude:'',
          distance:'1000',
          content:''
        },
        rangeList:[
          {name:"1公里",value:'1000'},
          {name:"2公里",value:'2000'},
          {name:"3公里",value:'3000'},
          {name:"4公里",value:'4000'}
        ],
        searchList:[],
        sendDeviceNum:''

			}
		},
    mounted() {
      this.id=this.$route.query.id;
      console.log("中间id:"+this.id);
      this.changeData(this.id);
    },
		methods:{
      changeData(id){
        let _self=this
        pcsCenterData(id).then(res => {
          _self.title=res.data.name;
          if(_self.title.length<9){
            _self.title+="实时看板";
          }else  if(_self.title.length<11){
            _self.title+="看板";
          }
          _self.lineList=res.data.list;
          _self.$nextTick(function() {
            _self.initAMap();
          });
        });
      },
      initAMap() {
        let _self=this;
        AMapLoader.load({
          key: '18997a286e73c2d94837b10f89516387', // 高德地图API Key
          version: '2.0', // 指定API版本
          plugins: ["AMap.ControlBar","AMap.ToolBar","AMapUI","AMapUI.PoiPicker",'AMap.PlaceSearch','AMap.AutoComplete'], // 需要使用的插件
          AMapUI: {
            version: '1.1',
            plugins:['overlay/SimpleMarker']
        }
        }).then((AMap) => {
          let longitude='116.333926';
          let latitude='39.997245';
          this.postionList=[];
          this.lineList.forEach((obj)=>{
            obj.pointList.forEach((pointObj)=>{
              this.postionList.push(pointObj);
            })
          })
          if(this.postionList.length>0){
            longitude=this.postionList[0].longitude;
            latitude=this.postionList[0].latitude;
          }
          console.log("longitude:"+longitude+";latitude"+latitude);
          this.map = new AMap.Map('container', {
            rotateEnable:true,
            pitchEnable:true,
            zoom: 17,
            rotation: -15,
            viewMode:'3D', //开启3D视图,默认为关闭
            zooms:[2,20],
            center:[longitude,latitude]
          });
          this.map.setMapStyle('amap://styles/darkblue');
          this.runDevice(AMap);
          this.setDeviceMaker(AMap);
           //输入提示
          var autoOptions = {
              input: "pickerInput",
              type: "", //数据类别
              pageSize: 10, //每页结果数,默认10
              pageIndex: 1, //请求页码，默认1
              extensions: "base" //返回信息详略，默认为base（基本信息）
          };
          var auto = new AMap.AutoComplete(autoOptions);
          var placeSearch = new AMap.PlaceSearch({
              map: this.map
          });  //构造地点查询类
          auto.on("select", select);//注册监听，当选中某条记录时会触发
          function select(e) {
            console.log(e,'eeeee')
              placeSearch.setCity(e.poi.adcode);
              placeSearch.search(e.poi.name);  //关键字查询查询
            _self.form.address=e.poi.name+"("+e.poi.district+e.poi.address+")";
            _self.form.latitude=e.poi.location.lat;
            _self.form.longitude=e.poi.location.lng;
          }
        }).catch(e => {
          console.log(e);
        });
      },
      runDevice(AMap){
        if(this.timer!=null){
          clearInterval(this.timer);
        }
        this.timer=setInterval(() => {
          this.setDeviceMaker(AMap);
        }, 1000*30)
      },
      setDeviceMaker(AMap){
        let _self=this;
        pcsListOnlineDevice(_self.id).then(res => {
          let makerList=[];
          res.data.forEach((obj)=>{
            let maker=this.getDeviceMaker(AMap,obj);
            makerList.push(maker);
          })
          _self.deviceMakerList.forEach(obj=>{
            this.map.remove(obj);
          })
          _self.deviceMakerList=makerList;
          this.map.add(makerList);
        });
      },
      getDeviceMaker(AMap,obj){
        //设置maker
        let title="巡逻机构:"+obj.groupName;
        title+="<br/>巡逻人:"+obj.workerName;
        title="<div style='padding: 10px;font-size: 22px;border-radius: 10px;background-color: #008080;color: #ffffff;line-height: 28px;'>"+title+"</div>"
        let makerPoint= new AMap.Marker({
          position: [obj.longitude, obj.latitude],
          icon: new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(60, 69),
            // 图标的取图地址
            image: 'https://zhab.oss-cn-beijing.aliyuncs.com/diy/diyGrid/202503051154062025030511540600890827879.png',
            // 图标所用图片大小
            imageSize: new AMap.Size(60, 69),
          }),
          offset: new AMap.Pixel(0,0), //设置偏移量
          label: {
            direction: 'top',
            content: title,
            offset: new AMap.Pixel(0, -5),
          }
        });
        return makerPoint;
      },
      showBj(){
        if(this.form.longitude!=""&&this.form.latitude!=""){
          this.dialogVisible=true;
          this.searchDevice()
        }else{
          alert("请先搜索详细地址");
        }
      },
      handleSelectChange(value) {
        console.log('选中的选项值:', value);
        // 在这里可以对选中的选项值进行任何操作
        this.form.distance=value;
        this.searchDevice();
      },
      searchDevice(){
        let _self=this;
        pcsSelectNearbyDeviceList(_self.id,this.form.latitude,this.form.longitude,this.form.distance).then(res => {
          if(res.data!=null && res.data.length>0){
            _self.searchList=res.data;
            _self.sendDeviceNum="共搜索到巡逻人员"+res.data.length+"人";
            let ids=[];
            _self.searchList.forEach(obj=>{
              ids.push(obj.id);
            })
            _self.form.workerIds=ids.join(",");
          }else{
            _self.sendDeviceNum="未搜索到巡逻人员";
          }
        });
      },
      sendSubmit(){
        if(!this.form.longitude){
          alert("请先搜索具体地址");
        }
        if(!this.form.workerIds){
          alert("未搜索到巡逻人员");
        }
        if(!this.form.content){
          alert("请输入呼叫内容");
        }
        let _self=this;
        sendSms(this.form).then(res => {
          if(res.code==0){
            alert("呼叫已经发送");
            _self.dialogVisible=false;
          }else{
            _self.sendDeviceNum="未搜索到巡逻人员";
          }
        });
      },

      handleClose() {
        this.dialogVisible=false;
      }
        


		}
	}
</script>

<style lang="scss">

	.baseBody{
		width:100%;height: 100vh;overflow: hidden;position:relative;
		font-size:14px;
		background:#000208;
		position:relative;
		.main-wraper{
			user-select: none;
			width:1920px;height:1080px;
			margin:0 auto;
			background: #02081a url('../../assets/images/sytBg2.png') center no-repeat;
			background-size: cover;
			box-sizing: border-box;
		}
	
	}
	.dateWeek{position:absolute;top:0;left:0;width:200px;height:50px;color:#ffffff; z-index: 200;}
	.fullBox{position:absolute;top:0;right:0;width:200px;height:50px;color:#ffffff;z-index: 999;}
	.nyBaseHeadTop {
		width: 100%;
		height: 80px;
		background: url('../../assets/images/titleBg.png') top center no-repeat;
		background-size: auto 80px
	}

	.base-headTit {
		font-size: 30px;
		font-weight: bold;
		box-sizing: border-box;
		padding-top: 24px;
	}

	.baseTitleLeft {
		width: 644px;
		height: 55px;
		background: url('../../assets/images/titleLeft.png') top right no-repeat;
		background-size: auto 55px
	}

	.baseTitleRight {
		width: 644px;
		height: 55px;
		background: url('../../assets/images/titleRight.png') top left no-repeat;
		background-size: auto 55px
	}

	.baseBody .baseTitleContent {
		width: 100%;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
    z-index: 200;
	}

	.baseBody .baseTitleContent .baseTitleMid {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/headtitileBg3.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .top {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/headtitileBg2.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .topLine {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/titleBg4.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .title {
		width: 502px;
		height: 70px;
		margin: 0 auto;
		font-size: 34px;
		font-weight: bold;
		line-height: 70px;
		background: url('../../assets/images/headtitileBg1.png') top left no-repeat;
		background-size: auto 70px
	}
  .baseCallAlarm{height:45px;line-height:45px;background:#FFFFFF;border-radius:7px;position:fixed;right:50px;top:10vh;z-index:999999;padding:0 20px;
    .bjImg{width:30px;height:30px;
        .el-image{width:30px;height:30px;}
       
    }
    .title{color:#4083f2;font-size:20px;font-weight:bold;margin-left:10px;}
    &:hover{cursor: pointer;box-shadow:0 0 20px rgba(64,131,242, 0.7);}
  }
 
  .basePoiBox{
    position:fixed;left:50px;top:10vh;z-index:999999;height:45px;line-height:45px;background:#FFFFFF;border-radius:7px;padding:0 5px 0 0;overflow: hidden;
    .relative{position: relative;
      input{height:44px;width:250px;margin:0 10px;border:none;vertical-align:top;font-size:16px;line-height:45px;padding:0 0 0 40px;}
		  i.el-icon-search{font-size:20px;color: #7c8196;font-weight:bold;cursor: pointer;}
      .poiIcon{width:20px;height:30px;position:absolute;left:12px;top:7px;
        .el-image{width:20px;height:30px}
      }
    }
		
	}

.amap-sug-result{
  .auto-item{font-size:14px;padding:10px;}
}
.dialogCustom{background:#052f61;border-radius:12px;}
.dialogCustom .el-dialog__header{display: none;}
.dialogCustom .el-form-item__label{color:#FFFFFF;}
.dialogCustom .dialogBox{padding-top: 30px;}
.dialogCustom .dialogBox .el-textarea__inner,.dialogCustom .dialogBox .el-input__inner{background: #164677;border:none;color:#FFFFFF;}
.base-font-16{font-size: 16px;}
.base-font-17{font-size: 17px;}
.base-font-18{font-size: 18px;}
.base-font-20{font-size: 20px;}
.dialogBtn{width:105px;height:40px;border-radius:3px;line-height:40px;font-weight:bold;font-size:15px;margin:0 15px;cursor: pointer;;}
.dialogBtn.qx{border:1px solid #3e76d2;color:#3e76d2;}
.dialogBtn.qd{border:1px solid #fffdda;background: #fffdda;color:#032f61;}
</style>
